<script lang="ts">
export default {
  mounted: function () {
    const updatingCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll("[id*='updating_user']") // eslint-disable-line no-undef

    updatingCheckboxes.forEach((updatingCheckbox: HTMLInputElement) => {
      updatingCheckbox.addEventListener('change', (event: Event) => {
        updatingCheckboxes.forEach((checkbox: HTMLInputElement) => {
          checkbox.checked = (event.target as HTMLInputElement).checked
        })
      })
    })
  }
}
</script>
