<template>
  <transition name="fade">
    <div
      class="modal"
      @click.self="$emit('close')"
    >
      <div class="modal__window">
        <div class="modal__content">
          <p
            class="modal__close"
            @click="$emit('close')"
          >
            ×
          </p>
          <p class="modal__title">
            住宅ローン金利について
          </p>
          <p>住宅ローン金利には、借りた時点で返済期期間中の金利が確定している（つまり返済額が確定する）「固定金利タイプ」と、借りた時点は返済期間中の金利が確定していない（つまり返済額は確定しない）「変動金利タイプ」があります。最初に検討をオススメする住宅ローン金利は、「フラット35」の最頻金利。2023年9 月現在、返済期間20年以下は頭金1割以上あり「1.320％」、20年超は「1.800％」です。</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    .modal__window {
      position: relative;
      width: 95vw;
      max-width: 640px;
      padding: 40px 64px;
      background: white;
      border-radius: 4px;
      overflow: hidden;

      .modal__content {
        padding: 10px 20px;

        .modal__close {
          position: absolute;
          top: 24px;
          right: 24px;
          margin: 0;
          color: #707070;
          font-size: 40px;
          cursor: pointer;
          line-height: 40px;
        }

        .modal__title {
          margin: 0 0 24px 0;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .modal {
      .modal__window {
        padding: 40px 24px;
        box-sizing: border-box;

        .modal__content {
          padding: 10px 0;
          line-height: 1.75em;

          .modal__close {
            top: 18px;
            right: 18px;
            font-size: 36px;
          }

          .modal__title {
            margin: 0 0 18px 0;
            font-size: 20px;
          }
        }
      }
    }
  }
</style>
