/**
 * シミュレーションで得られた予算結果から、該当する価格帯情報を返す
 */
export class PriceRange {
  /**
   * 価格帯に関する情報
   *
   * @returns {object}
   */
  get data () {
    return [
      { id: 1, name: '1,500万円未満', min: null, max: 1500 },
      { id: 2, name: '1,500万円〜2,000万円', min: 1500, max: 2000 },
      { id: 3, name: '2,000万円〜2,500万円', min: 2000, max: 2500 },
      { id: 4, name: '2,500万円〜3,000万円', min: 2500, max: 3000 },
      { id: 5, name: '3,000万円以上', min: 3000, max: null }
    ]
  }

  /**
   * シミュレーションで得られた予算結果から、該当する価格帯情報を返す
   *
   * @param {number} budget
   * @returns {object}
   */
  findByHouseBudget (budget) {
    if (typeof budget === 'undefined') {
      return undefined
    }

    const price = this.data.find(({ min, max }) => {
      if (min === null) {
        return budget < max
      } else if (max === null) {
        return min <= budget
      } else {
        return min <= budget && budget < max
      }
    })
    return price
  }
}
