<template>
  <DatePicker
    is-expanded
    v-model="date"
    :available-dates="availableDates"
    locale="ja"
    :masks="masks"
    :model-config="{ type: 'string', mask: dateFormat }"
    class="custom-calendar"
    :popover="popoverOption"
  >
    <template v-if="!isInline" v-slot="{ inputValue, inputEvents }">
      <input
        :value="inputValue"
        v-on="inputEvents"
        :name="name"
        :required="required ? true : false"
        class="form-control"
        :disabled="disabled"
      />
    </template>
  </DatePicker>
  <input
    v-if="isInline"
    type="hidden"
    :name="name"
    :value="date"
  />
</template>

<script lang="ts">
import { PropType } from 'vue'
import { DatePicker } from 'v-calendar'

interface Masks { input: string, title: string }

export default {
  components: { DatePicker },
  props: {
    name: {
      type: String,
      required: true
    },
    selectedDate: {
      type: String,
      default: ''
    },
    availableDates: {
      type: Array as PropType<string[]>,
      default: () => [] // eslint-disable-line jsdoc/require-jsdoc
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isInline: {
      type: Boolean,
      default: false
    }
  },
  created (): void {
    this.masks.input = this.dateFormat
  },
  data (): { date: string, masks: Masks, popoverOption: object } {
    return {
      date: this.selectedDate,
      masks: {
        title: 'YYYY MMM',
        input: ''
      },
      popoverOption: { visibility: 'click' }
    }
  }
}
</script>

<style lang="scss">
.custom-calendar {
  .vc-popover-content-wrapper {
    width: 90%;

    @media screen and (min-width: 576px) { // $sm-width: 576px;
      width: 70%;
    }

    @media screen and (min-width: 768px) { // $md-width: 768px;
      width: auto;
    }
  }
  .vc-container {
    width: 100%;
  }
}
</style>
