<template>
  <DatePicker
    is-expanded
    v-model="date"
    :selectAttribute="attribute"
    :min-date="availableDates[0]"
    :max-date="availableDates[availableDates.length - 1]"
    :available-dates="availableDates"
    locale="ja"
    :masks="masks"
    :model-config="{ type: 'string', mask: dateFormat }"
    class="custom-calendar"
    :popover="popoverOption"
  >
    <template v-if="!isInline" v-slot="{ inputValue, inputEvents }">
      <input
        :value="inputValue"
        v-on="inputEvents"
        :name="name"
        :required="required ? true : false"
        class="form-control bg-white"
        :disabled="disabled"
        readonly
      />
    </template>
    <template v-slot:day-content="{ day, dayEvents }">
      <span
        class="vc-day-content vc-focusbale mx-auto"
        :class="bindDayStateClass(day)"
        @click="onDayClick(day, dayEvents)"
      >
        {{ day.label }}
      </span>
    </template>
  </DatePicker>
  <input
    v-if="isInline"
    type="hidden"
    :name="name"
    :value="date"
  />
</template>

<script lang="ts">
import { PropType } from 'vue'
import { DatePicker } from 'v-calendar'

interface Masks { input: string, title: string }

export default {
  components: { DatePicker },
  props: {
    name: {
      type: String,
      required: true
    },
    selectedDate: {
      type: String,
      default: ''
    },
    availableDates: {
      type: Array as PropType<string[]>,
      default: () => [] // eslint-disable-line jsdoc/require-jsdoc
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isInline: {
      type: Boolean,
      default: false
    }
  },
  // eslint-disable-next-line jsdoc/require-jsdoc
  created (): void {
    this.masks.input = this.dateFormat
  },
  // eslint-disable-next-line jsdoc/require-jsdoc
  data (): { date: string, masks: Masks, popoverOption: object, attribute: object } {
    return {
      date: this.selectedDate,
      masks: {
        title: 'YYYY MMM',
        input: ''
      },
      popoverOption: { visibility: 'click' },
      // disable highlight
      attribute: {
        content: 'none',
        highlight: false
      }
    }
  },
  methods: {
    /**
     * 日付の状態によってclassを設定
     *
     * @param {object} day
     * @param {string} day.id
     * @returns {string}
     */
    bindDayStateClass (day: { id: string }):string {
      if (this.date === day.id) {
        return 'is-selected'
      } else if (this.availableDates.includes(day.id)) {
        return 'is-available'
      } else {
        return 'is-disabled'
      }
    },
    /**
     * @param {object} day
     * @param {string} day.id
     * @param {any} dayEvents
     */
    onDayClick (day: { id:string }, dayEvents): void {
      if (this.availableDates.includes(day.id)) {
        dayEvents.onClick()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.is-available {
  background: #50bec8;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  color: white;
  text-align: center;
  font-weight: var(--font-bold);
}
.is-selected {
  background: var(--blue-600);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  color: white;
  text-align: center;
  font-weight: var(--font-bold);
}
</style>
