import Finance from 'tvm-financejs'
const finance = new Finance()

export class HouseBudgetCalculator {
  /**
   * 1年（12ヶ月）
   *
   * @returns {number}
   */
  get month () { return 12 }

  /**
   * @param {object} input
   * @param {number} input.rent
   * @param {number | undefined} input.bonusAmount
   * @param {number} input.retirementAge
   * @param {number} input.age
   * @param {number} input.interestRate
   * @param {number | undefined} input.ownCapital
   */
  constructor ({
    rent,
    bonusAmount,
    retirementAge,
    age,
    interestRate,
    ownCapital
  }) {
    this.rent = rent
    this.bonusAmount = bonusAmount || 0
    this.retirementAge = retirementAge
    this.age = age
    this.interestRate = interestRate
    this.ownCapital = ownCapital || 0
  }

  /**
   * @returns {object}
   */
  calculate () {
    return { houseBudget: this.houseBudget(), borrowingLimit: this.borrowingLimit() }
  }

  /**
   * @returns {number}
   */
  houseBudget () {
    return Math.ceil(
      (finance.PV(
        this.interestRate / this.month,
        this.repaymentPeriod() * this.month,
        this.monthlyRepayment())
      ) + this.ownCapital
    )
  }

  /**
   * @returns {number}
   */
  borrowingLimit () {
    return this.houseBudget() - this.ownCapital
  }

  /**
   * @returns {number}
   */
  repaymentPeriod () {
    return this.retirementAge - this.age
  }

  /**
   * 月々の返済額を計算する。ユーザーはプラスの値を入力するが、PV関数に与える際にマイナス（支出）で与える必要があるため、-1 を掛けている
   * https://dekiru.net/article/4647/
   *
   * @returns {number}
   */
  monthlyRepayment () {
    return -1 * ((this.rent * this.month + this.bonusAmount) / this.month)
  }
}
