import Rails from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'
import { Ienavi } from 'src/javascripts/common/ienavi'
import 'src/javascripts/common/config/axios'
import 'src/javascripts/ienavi-frontend/controllers/application.js'
import 'bootstrap'
import 'src/stylesheets/ienavi-frontend/application'

// turbo drive は使用しないので無効化
Turbo.session.drive = false
Rails.start()

const context = require.context(
  'src/javascripts/',
  true,
  /^.\/(ienavi-frontend|common)\/.+(?<!\.spec)\.(vue|ts)$/
)

Ienavi.start(context)
require.context('src/images', true)
