<template>
  <div>
    <button type='button' class='btn mx-auto sw-btn--outline-blue--xl mb-4 ' data-bs-toggle='modal' data-bs-target='#saleHouseImages'>
      <img class='sw-btn--outline-blue--xl__icon' src='../../../../images/icon/images.png'>
      画像一覧（{{ saleHouseImages.length }}件)
    </button>
    <div class='modal fade' id='saleHouseImages' tabindex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
      <div class='modal-dialog modal-dialog-scrollable modal-xl'>
        <div class="modal-content">
          <div class='modal-header mb-3'>
            <span class='me-2 me-lg-3'>画像（{{ saleHouseImages.length }}件)</span>
            <button type='button' v-if="!listView" @click="resetView()" class='btn btn-sm btn-outline-secondary sale_house__main-images__reset-view-btn'>一覧に戻る</button>
            <button type='button' @click="resetView()" data-bs-dismiss='modal' aria-label='Close' class='btn-close'></button>
          </div>
          <div class='modal-body'>
            <div v-if="listView" class='row row-cols-3'>
              <div v-for="(image, index) in saleHouseImages" :key="image.image" class='sale_house__main-images__item'>
                <div @click="zoomView(index)" class='sale_house__main-images__item__inner'>
                  <img
                    :src="image.url"
                    height='550'
                    width='550'
                    class='sale_house__main-images__item__image'
                    loading='lazy'
                  >
                </div>
              </div>
            </div>
            <div v-else class='d-flex align-items-center w-100 h-100'>
              <div id='carouselExampleControls' class='carousel slide w-100' data-bs-ride='carousel' data-bs-interval='false'>
                <div class='carousel-inner d-flex align-items-center'>
                  <div v-for="(image, index) in saleHouseImages" :key="image.image" :class="{ active: index === this.selectedImageIndex }" class='carousel-item'>
                    <img :src="image.url" loading='lazy' class='mb-3 d-block' :alt="image.category">
                    <div class='pt-2 px-lg-2 fw-normal border-top'>{{ image.body }}</div>
                  </div>
                </div>
                <button class='carousel-control-prev' type='button' data-bs-target='#carouselExampleControls' data-bs-slide='prev'>
                  <span class='carousel-control-prev-icon' aria-hidden='true'></span>
                  <span class='visually-hidden'>Previous</span>
                </button>
                <button class='carousel-control-next' type='button' data-bs-target='#carouselExampleControls' data-bs-slide='next'>
                  <span class='carousel-control-next-icon' aria-hidden='true'></span>
                  <span class='visually-hidden'>Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    saleHouseImages: {
      type: Array,
      default: () => [] // eslint-disable-line jsdoc/require-jsdoc
    }
  },
  data (): { listView: boolean, selectedImageIndex: number } {
    return {
      listView: true,
      selectedImageIndex: 0
    }
  },
  methods: {
    /**
     * 画像を拡大表示（カルーセル表示）する
     *
     * @returns { undefined }
     */
    zoomView (index: number) {
      this.listView = !this.listView
      this.selectedImageIndex = index
    },
    /**
     * 表示状態（一覧 or カルーセル）をリセットする
     */
    resetView () {
      this.listView = true
    }
  }
}
</script>

<style lang="scss">
.sale_house__main-images {
  .sw-btn--outline-blue--xl.btn {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  &__reset-view-btn {
    font-size: 0.7rem;
    padding: 0.1rem 0.5rem;

    @media screen and (min-width: 992px) { // NOTE: $lg-width: 992px;
      font-size: 0.8rem;
      padding: 0 0.4rem;
    }
  }

  &__item {
    padding: 2px;

    &__inner {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 100%;

      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }

    &__image {
      position: absolute;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      border-radius: 0.25rem;
      cursor: pointer;
    }
  }

  // override bootstrap class
  .modal-content {
    height: 100%;

    .modal-body {
      padding-bottom: 2rem;
    }

    .modal-header, .modal-body {
      background: #fff;
    }

    .carousel-caption {
      position: initial;
      font-weight: normal;
      color: #222; // NOTE: $base-text-color: #222;
    }

    .carousel-item {
      font-size: .9rem;

      @media screen and (min-width: 992px) { // NOTE: $lg-width: 992px;
        font-size: 1rem;
      }

      > img {
        margin: 0 auto;
        max-height: 60vh;
        -o-object-fit: cover;
        object-fit: cover;

        @media screen and (min-width: 992px) { // NOTE: $lg-width: 992px;
          max-height: 70vh;
        }
      }
      > div {
        white-space: pre-wrap;
      }
    }
  }
}
</style>
