import { Application } from '@hotwired/stimulus'
import { OrderSelectController } from './order-select-controller.js'
import { CompanyAtLeastOneCheckedController } from './company-at-least-one-checked-controller.js'
import { CompanyBuildingPriceRangeChartController } from './company-building-price-range-chart-controller.js'

const application = Application.start()

application.debug = false
window.Stimulus = application

application.register('order-select', OrderSelectController)
application.register('company-at-least-one-checked', CompanyAtLeastOneCheckedController)
application.register('company-building-price-chart', CompanyBuildingPriceRangeChartController)
