<script lang="js">
export default {
  render: function () {
    return null // nothing to do
  },
  mounted: function () {
    const cagetoryNavs = document.getElementsByClassName('cagetoryNav')

    Array.from(cagetoryNavs).forEach(nav => {
      const clientWidth = nav.clientWidth // navの幅
      const scrollWidth = nav.scrollWidth // スクロール部分を含んだnavの幅

      if (clientWidth < scrollWidth) {
        const activeItem = nav.getElementsByClassName('active')[0]
        const x = activeItem.getBoundingClientRect().x - 12 // .container の 余白分を差し引いている
        nav.scrollTo(x, 0)
      }
    })
  }
}
</script>
