<template>
  <div>
    <button
      class="btn sw-mypage-btn"
      @click="signinFormOpen"
    >
      <img
        class='sw-mypage-btn__icon'
        src="../../../images/icon/mypage.png"
        alt=""
      >
      ログイン
    </button>

    <div
      id="signin-widget__overlay"
      v-if="data.open"
      @click.self="data.open = false"
    >
      <div class="signin-widget">
        <div class="sw-form mb-4 mb-lg-5">
          <div class='col-md-8 mx-auto'>
            <form action="/login" accept-charset="UTF-8" method="post" class="px-2" @submit.prevent="submit($event)">
              <input type="hidden" name="authenticity_token" :value="props.token" />
              <input type="hidden" name="back_url" :value="props.back_url" />
              <div class='mb-2 mb-lg-3'>
                <label class="form-label mb-1" for="session_email">メールアドレス</label>
                <input required="required" placeholder="（例）myhome-i@colorfulcompany.co.jp" class="form-control" type="email" name="session[email]" id="session_email" />
              </div>
              <div class='mb-2 mb-lg-3'>
                <label class="form-label mb-1" for="session_password">パスワード</label>
                <input placeholder="パスワード" class="form-control" type="password" name="session[password]" id="session_password" />
              </div>
              <div class='mb-2 mb-lg-3'>
                <label class="form-check-label" for="session_remember">
                  <input name="session[remember]" type="hidden" value="0" />
                  <input class="form-check-input" type="checkbox" value="1" name="session[remember]" id="session_remember" />
                  <span>ログインしたままにする</span>
                </label>
              </div>
              <div ref="recaptcha">
                <div class='mb-2 mb-lg-3' v-if="props.recaptcha_version == 'v2'">
                  <div class="g-recaptcha" :data-sitekey="props.recaptcha_site_key"></div>
                </div>
              </div>
              <div class='mb-2 mb-lg-3'>
                <input type="submit" name="commit" value="ログイン" class="btn w-100 mx-auto sw-btn--blue" data-disable-with="ログイン" />
              </div>
              <div class='text-center'>
                <a class="login__link" href="/password_resets/new">
                  パスワードをお忘れの方はこちら
                </a>
              </div>
            </form>
          </div>
        </div>
        <div class='sw-form'>
          <div class='col-md-7 col-lg-6 mx-auto px-2'>
            <a class="btn mx-auto sw-btn--blue" href="/users/new">新規登録はこちら</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup type="ts">
import { reactive, defineProps, ref, nextTick } from 'vue'
import { load } from 'recaptcha-v3'

const data = reactive({
  open: false
})

const props = defineProps({
  token: {
    type: String,
    require: true
  },
  back_url: {
    type: String,
    require: true
  },
  enable_recaptcha: {
    type: Boolean,
    require: true
  },
  recaptcha_site_key: {
    type: String,
    require: false,
    default: ''
  },
  recaptcha_version: {
    type: String,
    require: false,
    default: 'v3'
  }
})

const recaptcha = ref()

/**
 * submit before set recaptcha token
 *
 * @param {object} e
 */
const submit = async (e) => {
  if (enableRecaptcha('v3')) {
    const recaptchaClient = await load(props.recaptcha_site_key)
    const token = await recaptchaClient.execute('user_signin')
    const el = document.createElement('input')
    el.setAttribute('type', 'hidden')
    el.setAttribute('name', 'g-recaptcha-response-data[user_signin]')
    el.setAttribute('value', token)
    e.target.appendChild(el)
  }
  e.target.submit()
}

/**
 * sinin button click
 */
const signinFormOpen = () => {
  data.open = true
  nextTick(() => { loadRecaptcha() })
}

/**
 * loading recaptcha client
 */
const loadRecaptcha = () => {
  if (enableRecaptcha('v2')) {
    const el = document.createElement('script')
    el.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
    el.setAttribute('defer', 'defer')
    el.setAttribute('async', 'async')
    recaptcha.value.appendChild(el)
  }
}

/**
 * @param {String} version
 * @returns {Boolean}
 */
const enableRecaptcha = (version) => {
  return props.enable_recaptcha && props.recaptcha_version === version
}

</script>

<style scoped lang="scss">
#signin-widget__overlay {
  z-index: 20;

  /* cover all screen */
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0.5);

  /* disp on center */
  display: flex;
  align-items: center;
  justify-content: center;
}

.signin-widget {
  z-index: 21;
  width: 90%;
  max-width: 680px;
  padding: 1em;
  background-color: white;
  border-radius: 0.8%;

  @media screen and (min-width: 992px) { // NOTE: $lg-width: 992px;
    width: 50%;
  }
}
</style>
