<template>
  <div class='mx-auto sw-access__map' ref="googleMap" width="100%" height="100%" loading="lazy" />
</template>

<script lang="ts">
import { Loader } from '@googlemaps/js-api-loader'

interface LatLng {
  lat: number,
  lng: number
}

interface MapConfig {
  center: LatLng,
  zoom: number
}

export default {
  props: {
    lat: {
      type: Number,
      required: true
    },
    lng: {
      type: Number,
      required: true
    },
    zoom: {
      type: Number,
      default: 15
    }
  },
  data (): { latLng: LatLng } {
    return {
      latLng: { lat: 0, lng: 0 }
    }
  },
  computed: {
    mapConfig (): MapConfig {
      return { center: this.latLng, zoom: this.zoom }
    }
  },
  created (): void {
    this.latLng = { lat: this.lat, lng: this.lng }
  },
  async mounted (): Promise<void> {
    const loader = new Loader({ apiKey: process.env.GOOGLE_MAPS_API_KEY, version: 'weekly' })
    // https://github.com/googlemaps/js-api-loader#example
    //  - loader.load() で google object が取得できる
    const google = await loader.load()
    const map = new google.maps.Map(this.$refs.googleMap, this.mapConfig)
    new google.maps.Marker({ position: this.latLng, map }) // eslint-disable-line no-new
  }
}
</script>
