<template>
  <span class="text-success" v-if="updated">
    更新しました。
  </span>
  <span class="text-danger" v-if="failed">
    更新に失敗しました。
  </span>
</template>

<script lang="ts">
import axios from 'axios'

export default {
  data (): { select: object, url: string, updated: boolean, failed: boolean } {
    return {
      select: null,
      url: '/mypage/profile',
      updated: false,
      failed: false
    }
  },
  created () {
    this.select = document.getElementById('price_range_id')
    if (!this.select) { return }
    this.select.addEventListener('change', this.update)
  },
  methods: {
    params (): object {
      return { user: { price_range_id: this.select.value }, context: 'update_building_house' }
    },
    update (): void {
      axios.patch(this.url, this.params())
        .then(() => {
          this.updated = true
          setTimeout(() => { this.updated = false }, 2000)
        })
        .catch(() => {
          this.failed = true
          setTimeout(() => { this.failed = false }, 2000)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
span {
  display: inline-block;
  height: 30px;
  margin-left: 1em;
}
</style>
