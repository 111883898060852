<script lang="js">
export default {
  render: function () {
    return null // nothing to do
  },
  mounted: function () {
    this.setHeight()

    window.addEventListener('resize', () => {
      this.setHeight()
    })
  },
  methods: {
    setHeight () {
      const images = document.getElementsByClassName('carouselImage')
      const height = document.getElementById('slide').offsetWidth * 0.67 // 幅6:高4

      Array.from(images).forEach(e => { e.style.height = height + 'px' })
    }
  }
}
</script>
