import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'

/**
 * 建物本体価格のグラフ表示
 */
export class CompanyBuildingPriceRangeChartController extends Controller {
  static values = { ranges: Object }

  /** @returns {string} */
  get CHART_TYPE () { return 'pie' }
  /** @returns {Array} */
  get labels () { return Object.keys(this.rangesValue) }
  /** @returns {Array} */
  get values () { return Object.values(this.rangesValue) }

  /**
   * setup chart color
   *  - ColorBrewer のカラーパレットを利用
   *  - https://colorbrewer2.org/#type=sequential&scheme=GnBu&n=7
   *  - #4eb3d3 -> #50bec8 に調整
   *
   * @returns {Array}
   */
  backgroundColor () {
    return [
      '#f0f9e8',
      '#ccebc5',
      '#a8ddb5',
      '#7bccc4',
      '#50bec8',
      '#2b8cbe',
      '#08589e'
    ]
  }

  /**
   * @returns {object}
   */
  options () {
    return {
      plugins: {
        legend: {
          align: 'start'
        }
      }
    }
  }

  /**
   * connect event
   */
  connect () {
    // eslint-disable-next-line no-new
    new Chart(this.element, {
      type: this.CHART_TYPE,
      data: {
        labels: this.labels,
        datasets: [{
          label: '[%]',
          data: this.values,
          borderWidth: 1,
          backgroundColor: this.backgroundColor(),
          fill: false,
          animation: {
            animateRotate: false
          }
        }]
      },
      options: this.options()
    })
  }
}
