import { createApp } from 'vue'

export const Ienavi = {
  start: context => {
    const vueOptions = {}

    context.keys().forEach(key => {
      const name = key.match(/.+\/(.+)\.(vue|ts)$/)[1]
      vueOptions[name] = context(key).default
    })

    window.addEventListener('load', () => {
      const mountTargets = Array.from(document.querySelectorAll('[data-vue]'))

      mountTargets.forEach(el => {
        const vueOption = Object.assign(vueOptions[el.dataset.vue], { el })
        const props = el.dataset.props ? JSON.parse(el.dataset.props) : null
        const vm = createApp(vueOption, props)
        vm.mount(el)
      })
    })
  }
}
