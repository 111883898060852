<template>
  <multiselect
    v-model="selectedOption"
    label="label"
    track-by="value"
    placeholder="選択してください"
    :options="optionArray"
    :show-labels="false"
    :disabled="disabled"
    @select="select"
    @remove="remove"
  />
  <input type="hidden" :name="name" :value="selectedValue" :disabled="disabled" />
</template>

<script lang="ts">
import Multiselect from 'vue-multiselect'

interface Option { label: string, value: string|number }

export default {
  components: { Multiselect },
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    selected: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data (): { selectedOption: Option } {
    return {
      selectedOption: null
    }
  },
  created (): void {
    this.selectedOption = this.optionArray.find(option => option.value === this.selected)
  },
  computed: {
    optionArray (): Option[] {
      return Object.entries(this.options || {}).map(([key, value]: [string, string|number]) => ({ label: key, value: value }))
    },
    selectedValue (): string|number {
      return this.selectedOption?.value || ''
    }
  },
  emits: ['select', 'remove'],
  methods: {
    select (selectedOption: Option): void {
      this.$emit('select', selectedOption)
    },
    remove (selectedOption: Option): void {
      this.$emit('remove', selectedOption)
    },
    clear (): void {
      this.selectedOption = null
    }
  }
}
</script>
