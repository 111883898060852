<template>
  <div>
    <p class='mb-2 sw-form__text'>
      登録する前に<a href="/terms">利用規約</a>および<a href="/privacy_policy">プライバシーポリシー</a>をお読みください。
    </p>
    <p class='mb-3 form-check text-center'>
      <label class='form-check-label'>
        <input type="checkbox" v-model="checked" class='form-check-input'>
        利用規約とプライバシーポリシーに同意する
      </label>
    </p>
    <button v-bind:disabled="!checked" class='mx-auto w-100 w-md-50 btn sw-btn--blue sw-policy-btn'>
      <span v-if="!checked" class='sw-form__text'>
        利用規約とプライバシーポリシーに同意するを選択してください
      </span>
      <span v-else class='sw-form__text'>
        会員登録する
      </span>
    </button>
  </div>
</template>

<script lang="ts">
export default {
  name: 'signup-form-button',
  data: () : { checked: boolean } => {
    return {
      checked: false
    }
  }
}
</script>
