<template>
  <div class='mb-2 mb-lg-3'>
    <div class="mb-2 mb-lg-3 alert alert-danger" v-if="fetchFailed">
      郵便番号が誤っています。もう一度入力してください。
    </div>
    <div class="mb-2 mb-lg-3 flexibility">
      <label class="form-label mb-1" for="post_code">
        郵便番号
        <span :style="`color: #${requiredMarkColor};`">{{ requiredMark }}</span>
      </label>
      <div class="d-flex align-items-center row">
        <div class="col-8">
          <input
            placeholder="9218002"
            class="form-control"
            :name="`${namePrefix}[post_code]`"
            type="text"
            v-model="postCode"
            @keydown.enter.prevent="searchAddress()"
            :required="this.required ? true : false"
            id="post_code"
            autocomplete="postal-code"
          >
        </div>
        <div class="col-4">
          <button
            class="btn btn-secondary btn-sm"
            @click.prevent="searchAddress()"
            :disabled="disabled"
          >
            住所検索
          </button>
        </div>
      </div>
    </div>
    <div class="mb-2 mb-lg-3 flexibility">
      <label class="form-label mb-1" for="prefecture">
        都道府県
        <span :style="`color: #${requiredMarkColor};`">{{ requiredMark }}</span>
      </label>
      <input
        placeholder="（例）石川県"
        class="form-control"
        type="text"
        :name="`${namePrefix}[prefecture]`"
        v-model="prefecture"
        :required="this.required ? true : false"
        id="prefecture"
        autocomplete="address-level1"
      >
    </div>
    <div class="mb-2 mb-lg-3 flexibility">
      <label class="form-label mb-1" for="town">
        郡/市区町村
        <span :style="`color: #${requiredMarkColor};`">{{ requiredMark }}</span>
      </label>
      <input
        placeholder="（例）金沢市玉鉾"
        class="form-control"
        type="text"
        :name="`${namePrefix}[town]`"
        v-model="town"
        :required="this.required ? true : false"
        id="town"
        autocomplete="address-level2"
      >
    </div>
    <div class="mb-2 mb-lg-3 flexibility">
      <label class="form-label mb-1" for="street">
        番地
        <span :style="`color: #${requiredMarkColor};`">{{ requiredMark }}</span>
      </label>
      <input
        placeholder="（例）3-29"
        class="form-control"
        type="text"
        :name="`${namePrefix}[street]`"
        v-model="street"
        :required="this.required ? true : false"
        id="street"
        autocomplete="address-line1"
      >
    </div>
    <div class="mb-2 mb-lg-3 flexibility">
      <label class="form-label mb-1" for="building">以降の住所</label>
      <input
        placeholder="（例）XXマンション F10"
        class="form-control"
        type="text"
        :name="`${namePrefix}[building]`"
        v-model="building"
        id="building"
        autocomplete="address-line2"
      >
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import axios from 'axios'

export type ApiAddress = {
  prefecture: string,
  address1: string,
  address2: string,
  address3: string,
  address4: string,
}

export type ApiAddressInformation = {
  prefcode: string,
  ja: ApiAddress,
  en?: ApiAddress,
}

export type PostalCodeApiResponseData = {
  code: string,
  data: ApiAddressInformation[]
}

export type PostalCodeApiResponse = {
  data: PostalCodeApiResponseData
}

interface UserAddress {
  post_code?: string,
  prefecture?: string,
  town?: string,
  street?: string,
  building?: string,
}

interface Address {
  postCode: string,
  zipCode: string,
  areaCode: string,
  prefecture: string,
  town: string,
  street: string,
  building: string,
  address: object,
  fetchFailed: boolean,
  requiredMarkColor: string
}

export default {
  props: {
    namePrefix: {
      type: String,
      required: true
    },
    givenAddress: {
      type: Object as PropType<UserAddress>,
      default: () => { return {} } // eslint-disable-line jsdoc/require-jsdoc
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  created () {
    if (Object.keys(this.givenAddress).length === 0) { return }

    this.setAddressFromUser(this.givenAddress)
  },
  data (): Address {
    return {
      postCode: '',
      zipCode: '',
      areaCode: '',
      prefecture: '',
      town: '',
      street: '',
      building: '',
      address: {},
      fetchFailed: false,
      requiredMarkColor: 'dc3545'
    }
  },
  computed: {
    postalCodeURL (): string {
      return `https://madefor.github.io/postal-code-api/api/v1/${this.zipCode}/${this.areaCode}.json`
    },
    disabled (): boolean {
      return !this.postCode.match(/^[0-9]{3}-?[0-9]{4}$/)
    },
    requiredMark (): string {
      return this.required ? '※' : ''
    }
  },
  methods: {
    async searchAddress (): Promise<void> {
      this.separatePostCode()

      const fetchedSucceeded = await this.fetchAddress().then(() => { return true }).catch(() => { return false })
      if (fetchedSucceeded) { this.setAddressFromApi(this.address) }
    },
    async fetchAddress (): Promise<void> {
      await axios.get(this.postalCodeURL)
        .then((response: PostalCodeApiResponse) => {
          this.address = response.data.data[0].ja
        })
        .catch((error: Error) => {
          this.showErrorMessage()
          throw error
        })
    },
    setAddressFromApi (address: ApiAddress): void {
      this.prefecture = address.prefecture
      this.town = address.address1 + address.address2
      this.street = address.address3
      this.building = address.address4
    },
    showErrorMessage (): void {
      this.fetchFailed = true
      setTimeout(() => {
        this.fetchFailed = false
      }, 5000)
    },
    setAddressFromUser (address: UserAddress): void {
      this.postCode = address.post_code || ''
      this.prefecture = address.prefecture || ''
      this.town = address.town || ''
      this.street = address.street || ''
      this.building = address.building || ''
    },
    separatePostCode (): void {
      this.zipCode = this.postCode.replace(/^([0-9]{3}).*/, '$1')
      this.areaCode = this.postCode.replace(/.*([0-9]{4})$/, '$1')
    }
  }
}
</script>
