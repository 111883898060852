import Axios from 'axios'

Axios.interceptors.request.use((config) => {
  if (config.url.startsWith('/')) {
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
  }
  if (['post', 'put', 'patch', 'delete'].includes(config.method)) {
    const csrfTokenElement = document.querySelector('meta[name="csrf-token"]')
    if (csrfTokenElement !== null) {
      config.headers['X-CSRF-Token'] = csrfTokenElement.content
    }
  }

  // For system spec
  window.pendingRequests = window.pendingRequests || 0
  window.pendingRequests += 1

  return config
}, (error) => {
  return Promise.reject(error)
})

Axios.interceptors.response.use((response) => {
  window.pendingRequests -= 1

  return response
}, (error) => {
  window.pendingRequests -= 1

  return Promise.reject(error)
})
