import { Controller } from '@hotwired/stimulus'

/**
 * 一括資料請求 会社が１つ以上選択されたらsubmit button を有効にする
 */
export class CompanyAtLeastOneCheckedController extends Controller {
  static targets = ['companies', 'submit', 'errorMessage']

  /**
   * @returns {Array}
   */
  checkboxes () {
    return Array.from(this.companiesTarget.querySelectorAll('[type="checkbox"]'))
  }

  /**
   * @param {Array} checkboxes
   * @returns {boolean}
   */
  atLeastOneChecked (checkboxes) {
    return checkboxes.length > 0 && checkboxes.find((checkbox) => checkbox.checked)
  }

  /**
   * @param {Array} checkboxes
   */
  toggleSubmit (checkboxes) {
    if (this.atLeastOneChecked(checkboxes)) {
      this.submitTarget.disabled = false
      this.errorMessageTarget.hidden = true
    } else {
      this.submitTarget.disabled = true
      this.errorMessageTarget.hidden = false
    }
  }

  /**
   * checkbox change event
   */
  change () {
    this.toggleSubmit(this.checkboxes())
  }

  /**
   * connect event
   */
  connect () {
    this.toggleSubmit(this.checkboxes())
  }
}
