import { Controller } from '@hotwired/stimulus'

/**
 * 一括資料請求 並び順変更
 */
export class OrderSelectController extends Controller {
  static targets = ['select']
  /** @returns {string} */
  get QUERY_KEY () { return 'order' }

  /**
   * @returns {boolean}
   */
  isSelected () {
    return typeof this.selectTarget.value !== 'undefined' && this.selectTarget.value !== ''
  }

  /**
   * @param {string} search
   * @returns {string}
   */
  setQueryParams (search) {
    const params = new URLSearchParams(search)
    params.set(this.QUERY_KEY, this.selectTarget.value)

    return params.toString()
  }

  /**
   * select on order
   */
  change () {
    if (this.isSelected()) {
      const query = this.setQueryParams(window.location.search)
      window.location.href = `${window.location.origin}${window.location.pathname}?${query}`
    }
  }
}
