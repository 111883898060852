<template>
  <single-select
    name="user[planning_prefecture_id]"
    :options="prefectureOptions"
    :selected="selectedPrefecture"
    @select="selectPrefecture"
    @remove="removePrefecture"
  />
  <multiple-select
    ref="townSelect"
    v-if="currentPrefecture != null"
    name="user[planning_town_ids][]"
    :options="townOptions"
    :selected="selectedTowns"
  />
</template>

<script lang="ts">
import SingleSelect from '../../../common/components/SingleSelect.vue'
import MultipleSelect from '../../../common/components/MultipleSelect.vue'

interface Option { label: string, value: string|number }

export default {
  components: { SingleSelect, MultipleSelect },
  props: {
    prefectureOptions: {
      type: Object,
      required: true
    },
    selectedPrefecture: {
      type: Number,
      default: null
    },
    groupedTownOptions: {
      type: Object,
      required: true
    },
    selectedTowns: {
      type: Array,
      default: () => [] // eslint-disable-line jsdoc/require-jsdoc
    }
  },
  data (): { currentPrefecture: number } {
    return {
      currentPrefecture: this.selectedPrefecture
    }
  },
  computed: {
    townOptions (): { [key: string]: string|number } {
      return this.currentPrefecture ? this.groupedTownOptions[this.currentPrefecture] : {}
    }
  },
  methods: {
    selectPrefecture (selectedOption: Option): void {
      this.currentPrefecture = selectedOption.value
      this.$refs.townSelect?.clear()
    },
    removePrefecture (): void {
      this.currentPrefecture = null
    }
  }
}
</script>
