<template>
  <div class="d-md-flex align-items-center">
    <select
      name="school_town_id"
      class="form-select me-2 mb-2 mb-md-0"
      v-model="currentTownId"
      @change="currentElementarySchoolId = ''"
    >
      <option value="">校区市区町村選択</option>
      <option v-for="(value, label) in filteredTownOptions" :value="value" :key="value">
        {{ label }}
      </option>
    </select>

    <span class="me-2 d-none d-md-inline">＞</span>

    <select
      name="q[elementary_school_id_eq]"
      class="form-select me-2"
      v-model="currentElementarySchoolId"
    >
      <option value="">指定なし</option>
      <option v-for="(value, label) in elementarySchoolOptions" :value="value" :key="value">
        {{ label }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    townOptions: {
      type: Object,
      required: true
    },
    townId: {
      type: [String, Number],
      default: ''
    },
    groupedElementarySchoolOptions: {
      type: Object,
      required: true
    },
    elementarySchoolId: {
      type: [String, Number],
      default: ''
    }
  },
  data (): { filteredTownIds: number[], currentTownId: string|number, currentElementarySchoolId: string|number } {
    return {
      filteredTownIds: [],
      currentTownId: this.townId || '',
      currentElementarySchoolId: this.elementarySchoolId || ''
    }
  },
  created (): void {
    const checkboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll("input[id^='q_town_id_in_']") // eslint-disable-line no-undef

    this.filteredTownIds = [...checkboxes].filter(el => el.checked).map(el => Number(el.value))

    checkboxes.forEach((el: HTMLInputElement) => {
      el.addEventListener('change', () => {
        this.filteredTownIds = [...checkboxes].filter(el => el.checked).map(el => Number(el.value))
        this.currentTownId = ''
        this.currentElementarySchoolId = ''
      })
    })
  },
  computed: {
    filteredTownOptions (): { [key: string]: string|number } {
      if ((this.filteredTownIds).length === 0) { return this.townOptions }

      return Object.fromEntries(
        Object.entries(this.townOptions || {}).filter(([_, value]: [string, string|number]) => this.filteredTownIds.includes(value))
      )
    },
    elementarySchoolOptions (): { [key: string]: string|number } {
      return this.groupedElementarySchoolOptions[this.currentTownId] || {}
    }
  }
}
</script>
