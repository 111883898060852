<template>
  <multiselect
    v-model="selectedOptions"
    label="label"
    track-by="value"
    placeholder="選択してください"
    :options="optionArray"
    :show-labels="false"
    :multiple="true"
    :close-on-select="false"
    :disabled="disabled"
  />
  <input type="hidden" :name="name" value="" :disabled="disabled" />
  <select multiple :name="name" v-model="selectedValues" style="display: none">
    <option v-for="option in optionArray" :key="option.value" :value="option.value">
      {{ option.label }}
    </option>
  </select>
</template>

<script lang="ts">
import Multiselect from 'vue-multiselect'

interface Option { label: string, value: string|number }

export default {
  components: { Multiselect },
  props: {
    name: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      required: true
    },
    selected: {
      type: Array,
      default: () => [] // eslint-disable-line jsdoc/require-jsdoc
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data (): { selectedOptions: Option[] } {
    return {
      selectedOptions: []
    }
  },
  created (): void {
    this.selectedOptions = this.optionArray.filter(option => this.selected.includes(option.value))
  },
  computed: {
    optionArray (): Option[] {
      return Object.entries(this.options || {}).map(([key, value]: [string, string|number]) => ({ label: key, value: value }))
    },
    selectedValues (): (string|number)[] {
      return this.selectedOptions.map(option => option.value)
    }
  },
  methods: {
    clear (): void {
      this.selectedOptions = []
    }
  }
}
</script>
