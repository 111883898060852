<template>
  <div class="simulator">
    <div class="mainvisual">
      <img
        class="mainvisual__img"
        src="./assets/mvpc.jpg"
        srcset="./assets/mvsp.jpg 767w,
                ./assets/mvpc.jpg"
      >
      <p class="mainvisual__note">
        監修：FPサポート研究所 ファイナンシャルプランナー 高橋昌子
      </p>
    </div>
    <div class="step">
      <div class="step-content">
        <h3 class="step-content__title">
          <span class="step-content__number">01</span>
          ローンに充てられる<br class="sp">1カ月の金額を計算しよう
        </h3>
        <p class="step-content__lead">
          現在の家賃などをベースに返済可能額を計算します。その際に、年間返済可能額÷収入で、収入に対する割合をチェック。収入に対して20％以内が安心できる目安です。
        </p>
        <div class="step-item">
          <div class="step-item__left">
            <p class="step-item__title">
              1カ月家賃（万）<span class="step-item__require">必須</span>
            </p>
            <p class="step-item__note">
              ※半角数字
            </p>
            <p class="step-item__note">
              ※1万円単位でご入力ください
            </p>
          </div>
          <div class="step-item__right">
            <input
              v-model.number="rent"
              type="number"
              min="0"
              oninput="validity.valid||(value='')"
            >
            <p class="step-item__unit">
              万円
            </p>
          </div>
        </div>
        <div class="step-item">
          <div class="step-item__left">
            <p class="step-item__title">
              ボーナスで返済できる金額（年間）
            </p>
            <p class="step-item__note">
              ※半角数字
            </p>
            <p class="step-item__note">
              ※1万円単位でご入力ください
            </p>
          </div>
          <div class="step-item__right">
            <input
              v-model.number="bonusAmount"
              type="number"
              min="0"
              oninput="validity.valid||(value='')"
            >
            <p class="step-item__unit">
              万円
            </p>
          </div>
        </div>
      </div>
      <div class="step-content">
        <h3 class="step-content__title">
          <span class="step-content__number">02</span>
          返済期間を決めよう
        </h3>
        <p class="step-content__lead">
          定年の年齢までに完済できるようにするのが基本。繰上げ返済で返済期間の短縮が可能なら、それを考慮して返済期間を長めに設定してもよいでしょう。
        </p>
        <div class="step-item">
          <div class="step-item__left">
            <p class="step-item__title">
              定年年齢（歳）<span class="step-item__require">必須</span>
            </p>
            <p class="step-item__note">
              ※半角数字
            </p>
          </div>
          <div class="step-item__right">
            <input
              v-model.number="retirementAge"
              type="number"
              min="0"
              oninput="validity.valid||(value='')"
            >
            <p class="step-item__unit">
              歳
            </p>
          </div>
        </div>
        <div class="step-item">
          <div class="step-item__left">
            <p class="step-item__title">
              現在の年齢（歳）<span class="step-item__require">必須</span>
            </p>
            <p class="step-item__note">
              ※半角数字
            </p>
          </div>
          <div class="step-item__right">
            <input
              v-model.number="age"
              type="number"
              min="0"
              oninput="validity.valid||(value='')"
            >
            <p class="step-item__unit">
              歳
            </p>
          </div>
        </div>
      </div>
      <div class="step-content">
        <h3 class="step-content__title">
          <span class="step-content__number">03</span>
          金利と自己資産を考えよう
        </h3>
        <p class="step-content__lead">
          まず検討してほしい住宅ローン金利は「フラット35」の最頻金利。自己資金は、現在の貯蓄額からマイホーム資金として出せる金額、贈与資金があるのかを確認しましょう。
        </p>
        <div class="step-item">
          <div class="step-item__left">
            <p class="step-item__title">
              金利<span class="step-item__require">必須</span>
            </p>
            <p
              class="step-item__link"
              @click="openModal"
            >
              住宅ローン金利について
            </p>
            <transition name="fade">
              <InterestRateGuidance
                v-if="modal"
                @close="closeModal"
              />
            </transition>
          </div>
          <div class="step-item__right">
            <select v-model="interestRate">
              <option
                v-for="interestRateOption in interestRateOptions"
                :key="interestRateOption.key"
                :value="interestRateOption.value"
              >
                {{ interestRateOption.key }}
              </option>
            </select>
            <p class="step-item__unit">
              %
            </p>
          </div>
        </div>
        <div class="step-item">
          <div class="step-item__left">
            <p class="step-item__title">
              自己資金
            </p>
            <p class="step-item__note">
              ※半角数字
            </p>
            <p class="step-item__note">
              ※1万円単位でご入力ください
            </p>
          </div>
          <div class="step-item__right">
            <input
              v-model.number="ownCapital"
              type="number"
              min="0"
              oninput="validity.valid||(value='')"
            >
            <p class="step-item__unit">
              万円
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="button-wrap">
      <button
        class="button button__submit"
        :disabled="!isValid"
        @click="execute"
      >
        <img
          src="./assets/calculator.svg"
          class="button__img"
        >
        <span>計算する</span>
      </button>
    </div>
    <transition name="fade">
      <div
        ref="result"
        class="result"
      >
        <div class="result-content__wrap">
          <div class="result-content">
            <h3 class="result-content__title">
              <span>シミュレーション結果</span>
            </h3>
            <div class="result-item__wrap">
              <div class="result-item">
                <p class="result-item__title">
                  借入限度額
                </p>
                <p class="result-item__value">
                  {{ borrowingLimitStr }}<span class="result-item__unit">万円</span>
                </p>
              </div>
              <div class="result-item">
                <p class="result-item__title">
                  マイホーム予算<span>（借入限度額＋自己資金）</span>
                </p>
                <p class="result-item__value">
                  {{ houseBudgetStr }}<span class="result-item__unit">万円</span>
                </p>
              </div>
            </div>
            <p class="result-content__note">
              ※このシミュレーションは金利の変わらない長期固定金利を想定して、計算しています。
            </p>
            <p class="result-content__note">
              ※シミュレーションの結果は、あくまでも目安です。実際にローン契約をする際は、詳細を金融機関にご確認ください。
            </p>
            <p class="result-content__note">
              ※ここで算出した金額のうち、<span class="result-content__note-accent">10%程度が諸費用の支払いに必要</span>になります。
            </p>
            <img
              src="./assets/illust.png"
              class="result-content__img"
            >
          </div>
        </div>
      </div>
    </transition>

    <div class="sw-section-title">
      <div class="sw-section-title__main-title">{{ priceRangeName }}建築実例を見てみよう！</div>
    </div>
    <div class='home-guide__body__links mx-0'>
      <div class='d-inherit d-xl-flex home-guide__body__links__inner'>
        <a class="mb-2 mb-xl-0 home-area-btn-ishikawa--sm" :href="'/ishikawa/built_houses' + queryParam" data-track="true" data-action="click" data-category="in_simulator" data-label="【石川】建築実例">
          <picture class='home-area-btn-ishikawa__icon-sm'>
            <source srcset="../../../../../src/images/site-top/icon-ishikawa-md.png" type="image/webp"  media="(min-width: 992px)" />
            <img src="../../../../../src/images/site-top/icon-ishikawa-sm.png" />
          </picture>
          <span class='home-area-btn__text'><span class='home-area-btn-ishikawa__highlight'>石川</span>の<em class='home-area-btn__em--sm'>建築実例</em>を見る</span>
        </a>
        <a class="mb-2 mb-xl-0 home-area-btn-toyama--sm" :href="'/toyama/built_houses' + queryParam" data-track="true" data-action="click" data-category="in_simulator" data-label="【富山】建築実例">
          <picture class='home-area-btn-toyama__icon-sm'>
            <source srcset="../../../../../src/images/site-top/icon-toyama-md.png" type="image/webp"  media="(min-width: 992px)" />
            <img src="../../../../../src/images/site-top/icon-toyama-sm.png" />
          </picture>
          <span class='home-area-btn__text'><span class='home-area-btn-toyama__highlight'>富山</span>の<em class='home-area-btn__em--sm'>建築実例</em>を見る</span>
        </a>
        <a class="home-area-btn-fukui--sm" :href="'/fukui/built_houses' + queryParam" data-track="true" data-action="click" data-category="in_simulator" data-label="【福井】建築実例">
          <picture class='home-area-btn-fukui__icon-sm'>
            <source srcset="../../../../../src/images/site-top/icon-fukui-md.png" type="image/webp"  media="(min-width: 992px)" />
            <img src="../../../../../src/images/site-top/icon-fukui-sm.png" />
          </picture>
          <span class='home-area-btn__text'><span class='home-area-btn-fukui__highlight'>福井</span>の<em class='home-area-btn__em--sm'>建築実例</em>を見る</span>
        </a>
      </div>
    </div>
    <div class="button-wrap">
      <p class="button__lead">
        家づくりのTODOがわかる!
      </p>
      <a
        href="/login"
        target="_blank"
        class="button button__cv"
      >
        会員登録はこちらから
      </a>
    </div>
  </div>
</template>

<script>
import { HouseBudgetCalculator } from './house-budget-calculator.js'
import InterestRateGuidance from './InterestRateGuidance.vue'
import { PriceRange } from '../../models/price-range.js'

export default {
  name: 'BudgetSimulator',
  components: { InterestRateGuidance },
  data () {
    return {
      // ユーザーが入力する項目
      rent: undefined, // 1ヶ月家賃（万）
      bonusAmount: undefined, // ボーナスなどから充てられる年間金額
      retirementAge: undefined, // 定年年齢（歳）
      age: undefined, // 現在の年齢（歳）
      interestRate: undefined, // 金利
      interestRateOptions: [
        { key: '0.3', value: 0.003 }, { key: '0.4', value: 0.004 },
        { key: '0.5', value: 0.005 }, { key: '0.6', value: 0.006 }, { key: '0.7', value: 0.007 },
        { key: '0.8', value: 0.008 }, { key: '0.9', value: 0.009 }, { key: '1.0', value: 0.010 },
        { key: '1.1', value: 0.011 }, { key: '1.2', value: 0.012 }, { key: '1.3', value: 0.013 },
        { key: '1.4', value: 0.014 }, { key: '1.5', value: 0.015 }, { key: '1.6', value: 0.016 },
        { key: '1.7', value: 0.017 }, { key: '1.8', value: 0.018 }, { key: '1.9', value: 0.019 },
        { key: '2.0', value: 0.020 }, { key: '2.1', value: 0.021 }, { key: '2.2', value: 0.022 },
        { key: '2.3', value: 0.023 }, { key: '2.4', value: 0.024 }],
      ownCapital: undefined, // 自己資金
      // ユーザーへのフィードバック
      houseBudget: undefined, // マイホーム予算
      borrowingLimit: undefined, // 借入限度額
      // 出力を制御するフラグ
      modal: false
    }
  },
  computed: {
    isValid () {
      return (this.rent && this.retirementAge && this.age && this.interestRate)
    },
    /**
     * 表示テキスト用として、予算シミュレーション結果を数値から文字列に変換する
     *
     * @returns {string}
     */
    houseBudgetStr () {
      return typeof this.houseBudget !== 'undefined' ? this.houseBudget.toLocaleString() : '---'
    },
    /**
     * 表示テキスト用として、借入限度額シミュレーション結果を数値から文字列に変換する
     *
     * @returns {string}
     */
    borrowingLimitStr () {
      return typeof this.borrowingLimit !== 'undefined' ? this.borrowingLimit.toLocaleString() : '---'
    },
    /**
     * シミュレーション結果に応じてリンクのクエリパラメータを変更する
     *
     * @returns {string}
     */
    queryParam () {
      const defaultParam = 'R=money_simulator'
      const priceRange = new PriceRange().findByHouseBudget(this.houseBudget)
      const priceRangeParam = typeof priceRange !== 'undefined' ? `q%5Bprice_range_id_in%5D%5B%5D=${priceRange.id}#searchResult` : undefined

      return `?${[
        defaultParam,
        priceRangeParam
      ].filter((prm) => typeof prm !== 'undefined').join('&')}`
    },
    /**
     * シミュレーション結果に応じて価格帯を表現するテキストを変更する
     *
     * @returns {string}
     */
    priceRangeName () {
      const priceRange = new PriceRange().findByHouseBudget(this.houseBudget)

      return typeof priceRange !== 'undefined' ? `${priceRange.name}の` : ''
    }
  },
  methods: {
    execute () {
      this.calculate()
      // シミュレーション結果部分までスクロールする
      this.scrollToResult()
    },
    calculate () {
      const houseBudgetCalculator = new HouseBudgetCalculator(
        {
          rent: this.rent,
          bonusAmount: this.bonusAmount,
          retirementAge: this.retirementAge,
          age: this.age,
          interestRate: this.interestRate,
          ownCapital: this.ownCapital
        }
      )
      const result = houseBudgetCalculator.calculate()
      this.houseBudget = result.houseBudget
      this.borrowingLimit = result.borrowingLimit
    },
    scrollToResult () {
      const el = this.$refs.result
      el.scrollIntoView({ behavior: 'smooth' })
    },
    openModal () {
      this.modal = true
    },
    closeModal () {
      this.modal = false
    }
  }
}
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100&display=swap');

  .simulator {
    font-family: YuGothic, "Yu Gothic Medium", "Yu Gothic", 游ゴシック, BlinkMacSystemFont, Roboto, "Segoe UI", "Helvetica Neue", HelveticaNeue, Verdana, Meiryo, "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif;

    .mainvisual {
      width: 100%;

      .mainvisual__img {
        width: 100%;
        object-fit: cover;
      }

      .mainvisual__note {
        margin: 4px 4px 0 0;
        font-size: 12px;
        text-align: right;
      }
    }

    .step {
      width: 100%;
      max-width: 800px;
      margin: 80px auto;

      .step-content {
        .step-content__title {
          margin: 0 0 20px 0;
          padding: 0 0 4px 0;
          font-size: 28px;
          font-weight: bold;
          border-bottom: 1px solid #333;

          .step-content__number {
            font-family: 'Barlow', sans-serif;
            font-size: 58px;
            color: #50bec8;
            letter-spacing: 4px;
          }
        }

        .step-content__lead {
          margin: 0 0 40px 0;
          font-size: 14px;
        }

        .step-item {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          .step-item__left {
            .step-item__title {
              display: flex;
              align-items: center;
              margin: 0 0 4px 0;
              font-size: 20px;
              font-weight: bold;
              font-feature-settings: "palt";

              .step-item__require {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin: 0 0 0 4px;
                padding: 2px 8px;
                color: white;
                font-size: 12px;
                background-color: #ffa234;
                border-radius: 2px;
              }
            }

            .step-item__note {
              margin: 0 0 2px 0;
              font-size: 13px;
            }

            .step-item__link {
              margin: 0;
              font-size: 14px;
              color: #1a0dab;
              border-bottom: 1px solid #1a0dab;
              text-decoration: none;
              cursor: pointer;
            }
          }

          .step-item__right {
            display: flex;
            align-items: flex-end;

            .step-item__unit {
              width: 34px;
              margin: 0;
              font-size: 16px;
              text-align: right;
            }

            input,
            select {
              width: 200px;
              padding: 4px 8px;
              font-size: 22px;
              box-sizing: border-box;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              margin: 0;
              -webkit-appearance: none;
            }

            input[type=number] {
              -moz-appearance: textfield;
            }
          }
        }

        .step-item:not(:last-child) {
          margin: 0 0 24px 0;
        }
      }

      .step-content:not(:last-child) {
        margin: 0 0 64px 0;
      }
    }

    .result {
      width: 100%;
      max-width: 800px;
      margin: 24px auto 80px;

      .result-content__wrap {
        margin: 0;

        .result-content {
          position: relative;
          margin: 0 0 64px 0;
          padding: 40px 48px;
          border: 2px solid #c9c9c9;
          border-radius: 16px;

          .result-content__title {
            margin: 0;
            text-align: center;

            span {
              padding: 0 8px;
              font-size: 28px;
              text-align: center;
              background: linear-gradient(transparent 60%, #dcf2f4 0);
            }
          }

          .result-item__wrap {
            display: flex;
            justify-content: center;

            .result-item {
              display: flex;
              align-items: center;

              .result-item__title {
                margin: 0 16px 0 0;
                font-size: 20px;
                font-weight: bold;
                font-feature-settings: "palt";

                span {
                  display: block;
                  font-size: 14px;
                }
              }

              .result-item__value {
                margin: 24px 0;
                color: #f82055;
                font-size: 44px;
                font-weight: bold;

                .result-item__unit {
                  margin: 0 0 0 4px;
                  font-size: 16px;
                }
              }
            }

            .result-item:not(:last-child) {
              margin: 0 40px 0 0;
            }
          }

          .result-content__img {
            position: absolute;
            right: -7px;
            bottom: -40px;
            width: 240px;
          }

          .result-content__note {
            margin: 0 0 4px 0;
            font-size: 14px;

            .result-content__note-accent {
              font-weight: bold;
              background: linear-gradient(transparent 60%, #ff6 60%);
            }
          }
        }
      }
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.4s;
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }

    .button-wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: 800px;
      margin: 40px auto;

      .button {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 530px;
        padding: 20px 0;
        text-decoration: none;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        border-radius: 6px;
        transition: 0.2s;
        cursor: pointer;
      }

      .button__lead {
        display: block;
        width: 100%;
        margin: 0 0 4px 0;
        font-size: 14px;
        text-align: center;

        &::before {
          content: "";
          display: inline-block;
          width: 1px;
          height: .8rem;
          margin-right: .5rem;
          background: #222;
          transform: rotate(-30deg);
          vertical-align: text-bottom;
        }

        &::after {
          content: "";
          display: inline-block;
          width: 1px;
          height: .8rem;
          margin-left: .5rem;
          background: #222;
          transform: rotate(30deg);
          vertical-align: text-bottom;
        }
      }

      .button__cv {
        background-color: #ffa234;
        color: white;

        &:hover {
          background-color: #ff8b01;
          box-shadow: 0 0 0 5px rgba(255, 162, 52, 0.3);
        }
      }

      .button__submit {
        background-color: #50bec8;
        color: white;
        border: none;

        &:hover {
          background-color: #37a4ae;
        }
      }

      .button__submit:disabled {
        cursor: unset;
        background-color: #9b9b9b;

        &:hover {
          opacity: 1;
        }
      }

      .button__img {
        width: 32px;
        margin: 0 16px 0 0;
      }

      .button__clear {
        margin: 24px 0 0 0;
        width: 320px;
        font-size: 20px;
        background: white;
        border: solid 2px #50bec8;
        color: #50bec8;
      }
    }

    .pc {
      display: block;
    }

    .sp {
      display: none;
    }
  }

  @media only screen and (max-width: 767px) {
    .simulator {
      .step {
        margin: 40px auto;

        .step-content {
          .step-content__title {
            display: flex;
            align-items: center;
            font-size: 20px;

            .step-content__number {
              font-size: 48px;
              margin: 0 6px 0 0;
            }
          }

          .step-content__lead {
            margin: 0 0 24px 0;
          }

          .step-item {
            display: flex;
            align-items: flex-end;

            .step-item__left {
              .step-item__title {
                font-size: 16px;
              }

              .step-item__link {
                display: inline-block;
                margin: 0 0 8px 0;
              }
            }

            .step-item__right {
              .step-item__unit {
                margin: 0 0 2px 0;
              }

              input,
              select {
                width: 84px;
                margin: 0 0 4px 0;
                padding: 2px 4px;
                font-size: 20px;
                box-sizing: border-box;
              }

              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                margin: 0;
                -webkit-appearance: none;
              }

              input[type=number] {
                -moz-appearance: textfield;
              }
            }
          }

          .step-item:not(:last-child) {
            margin: 0 0 24px 0;
          }
        }

        .step-content:not(:last-child) {
          margin: 0 0 64px 0;
        }
      }

      .result {
        margin: 16px auto 40px;

        .result-content__wrap {
          margin: 0;

          .result-content {
            margin: 0 0 88px 0;
            padding: 32px 24px;

            .result-content__title {
              margin: 0 0 12px 0;

              span {
                font-size: 22px;
              }
            }

            .result-item__wrap {
              flex-wrap: wrap;
              margin: 0 0 24px 0;

              .result-item {
                display: flex;

                .result-item__title {
                  width: 130px;
                  margin: 0 16px 0 0;
                  font-size: 18px;
                  font-weight: bold;

                  span {
                    font-size: 12px;
                  }
                }

                .result-item__value {
                  margin: 0;
                  font-size: 38px;

                  .result-item__unit {
                    font-size: 14px;
                  }
                }
              }

              .result-item:not(:last-child) {
                margin: 0;
              }
            }

            .result-content__img {
              bottom: -50px;
              width: 180px;
            }

            .result-content__note {
              margin: 0 0 4px 0;
              font-size: 14px;
            }
          }
        }
      }

      .fade-enter-active,
      .fade-leave-active {
        transition: opacity 0.4s;
      }

      .fade-enter-from,
      .fade-leave-to {
        opacity: 0;
      }

      .button-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin: 40px 0 24px;

        .button {
          padding: 16px 0;
          font-size: 20px;
        }

        .button__lead {
          display: block;
          width: 100%;
          margin: 0 0 4px 0;
          font-size: 14px;
          text-align: center;
        }

        .button__cv {
          background-color: #ffa234;
          color: white;

          &:hover {
            background-color: #ff8b01;
            box-shadow: 0 0 0 5px rgba(255, 162, 52, 0.3);
          }
        }

        .button__submit {
          background-color: #50bec8;
          color: white;
          border: none;

          &:hover {
            background-color: #37a4ae;
          }
        }

        .button__submit:disabled {
          cursor: unset;
          background-color: #9b9b9b;

          &:hover {
            opacity: 1;
          }
        }

        .button__img {
          width: 32px;
          margin: 0 16px 0 0;
        }
      }

      .pc {
        display: none;
      }

      .sp {
        display: block;
      }
    }
  }
</style>
