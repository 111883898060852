<template>
  <div class='form-check'>
    <label>
      <input
        class="form-check-input"
        type="checkbox"
        v-model="inputChecked"
        @click="toggleTask()"
      />
      <span class='form-check-label'>{{ label }}</span>
      <span class="text-danger" v-if="failed">
        <small>更新に失敗しました。</small>
      </span>
    </label>
  </div>
</template>

<script lang="ts">
import axios from 'axios'

export default {
  props: {
    step: {
      type: Number,
      required: true
    },
    task: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  data (): { inputChecked: boolean, failed: boolean } {
    return {
      inputChecked: this.checked,
      failed: false
    }
  },
  computed: {
    url (): string {
      return `/mypage/steps/${this.step}/tasks/${this.task}`
    }
  },
  methods: {
    toggleTask (): Promise<void> {
      axios.patch(this.url)
        .catch(() => {
          this.inputChecked = !this.inputChecked
          this.failed = true
          setTimeout(() => { this.failed = false }, 2000)
        })
    }
  }
}
</script>
