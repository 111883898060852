<template>
  <template v-if="loggedIn">
    <a v-if="favoriteAdded" @click="remove()" class="me-3 btn sw-favorite-btn sw-favorite-btn--active" data-track="true" data-action="click" data-category="favorite_remove" :data-label="dataLabel">
      <div class="sw-favorite-btn__icon"></div>
      <span class="sw-favorite-btn__text">お気に入り済み</span>
    </a>
    <a v-else @click="add()" class="me-3 btn sw-favorite-btn sw-favorite-btn--inactive" data-track="true" data-action="click" data-category="favorite" :data-label="dataLabel">
      <div class="sw-favorite-btn__icon"></div>
      <span class="sw-favorite-btn__text">お気に入り登録</span>
    </a>
  </template>
  <a v-else href="/login" class="me-3 btn sw-favorite-btn sw-favorite-btn--inactive" data-track="true" data-action="click" data-category="favorite" :data-label="dataLabel">
    <div class="sw-favorite-btn__icon"></div>
    <span class="sw-favorite-btn__text">お気に入り登録</span>
  </a>
</template>

<script lang="ts">
import axios from 'axios'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    added: {
      type: Boolean,
      default: false
    },
    loggedIn: {
      type: Boolean,
      default: false
    },
    dataLabel: {
      type: String,
      required: true
    }
  },
  data (): { favoriteAdded: boolean, url: string, params: object } {
    return {
      favoriteAdded: this.added,
      url: '/mypage/favorites',
      params: { favorable_id: this.id, favorable_type: this.type }
    }
  },
  methods: {
    add (): void {
      axios.post(this.url, this.params)
        .then(() => {
          this.favoriteAdded = true
        })
    },
    remove (): void {
      axios.delete(this.url, { data: this.params })
        .then(() => {
          this.favoriteAdded = false
        })
    }
  }
}
</script>
