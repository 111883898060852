<template>
  <span @click.once="remove()">
    お気に入りから削除
  </span>
</template>

<script lang="ts">
import axios from 'axios'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    selector: {
      type: String,
      default: 'li'
    }
  },
  data (): { url: string, params: object } {
    return {
      url: '/mypage/favorites',
      params: { favorable_id: this.id, favorable_type: this.type }
    }
  },
  methods: {
    remove (): void {
      axios.delete(this.url, { data: this.params })
        .then(() => {
          const element = this.$el.closest(this.selector)
          const animation = element.animate([{ opacity: '1' }, { opacity: '0' }], 500)
          animation.onfinish = () => element.remove()
        })
    }
  }
}
</script>
