<template>
  <DatePicker
    is-expanded
    :min-date="availableDates[0]"
    :available-dates="availableDates"
    :model-value="false"
    :model-config="{ type: 'string', mask: dateFormat }"
  >
    <template v-slot:day-content='props'>
      <span
        class="vc-day-content vc-focusbale mx-auto"
        :class="isAvailable(props.day) ? 'available' : 'is-disabled'"
        @click="onDayClick(props.day)"
      >
        {{ props.day.day }}
      </span>
    </template>
  </DatePicker>
</template>

<script lang="ts">
import { DatePicker } from 'v-calendar'

export default {
  components: {
    DatePicker
  },
  props: {
    url: {
      type: String,
      required: true
    },
    availableDates: {
      type: Array,
      default: () => [] // eslint-disable-line jsdoc/require-jsdoc
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD'
    }
  },
  methods: {
    /**
     * @param {object} date
     * @param {string} date.id
     * @param {boolean} date.isDisabled
     */
    onDayClick (date: { id: string, isDisabled: boolean }): void {
      if (this.isAvailable(date)) {
        window.location.href = `${this.url}?date=${date.id}`
      }
    },
    /**
     * @param {object} date
     * @param {string} date.id
     * @param {boolean} date.isDisabled
     * @returns {boolean}
     */
    isAvailable (date: { id: string, isDisabled: boolean }):boolean {
      return this.availableDates.includes(date.id)
    }
  }
}
</script>

<style scoped lang="scss">
.available {
  background: #50bec8;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  color: white;
  text-align: center;
}
</style>
